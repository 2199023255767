export default {
  state: {
    priceForNewProduct: 0,
    product: null,
    products: [],
  },

  mutations: {
    SET_PRICE_FOR_NEW_PRODUCT(state, price) {
      state.priceForNewProduct = price;
    },

    SET_PRODUCT(state, product) {
      state.product = product;
    },

    SET_PRODUCTS(state, products) {
      state.products = products;
    },

    SET_CURRENCY_VALUES(state, payload) {
      state.currencyValues = payload.currencies;
    }
  },

  actions: {
    async createProduct(_, product) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post('/api/admin/products/create', product);
        this.$notifySuccess("Товар добавлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async draftProduct(_, product) {
      try {
        /* eslint-disable-next-line no-undef */
        return await axios.post('/api/admin/products/draft', product);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getPrice({
      commit
    }, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.post('/api/admin/products/count-price', payload);
        commit("SET_PRICE_FOR_NEW_PRODUCT", response.data);

      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getProduct({
      commit
    }, product_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/products/show/${product_id}`);
        commit("SET_PRODUCT", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async currencyUpdate(_) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.post(`/api/admin/products/update-currency`);
        console.log(response);
        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getProductImage(_, image_url) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.post('/api/admin/products/download-image', {
          image_url
        });
        return response.data;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async removeProduct(_, product_id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.delete(`/api/admin/products/${product_id}`);
        this.$notifySuccess("Товар удален");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async searchProducts({
      commit
    }, payload) {
      try {
        let url = "/api/admin/products/search-products?";
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.id || payload.id == 0) {
          url += `&id=${payload.id}`;
        }

        if (payload.name) {
          url += `&name=${payload.name}`;
        }

        if (payload.category) {
          url += `&category=${payload.category}`;
        }

        if (payload.brand) {
          url += `&brand=${payload.brand}`;
        }

        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url, payload);
        commit("SET_PRODUCTS", response.data);

      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateProduct(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post(`/api/admin/products/${payload.id}`, payload.product);
        this.$notifySuccess("Товар обновлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
