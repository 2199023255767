export default {
  state: {
    deliveryStatuses: [
      "Ожидается оплата",
      "Оплачен",
      "В пути",
      "Отменено",
      "Завершено"
    ],
    orderStatuses: [],
    orderInXmlFormat: null,
    order: null,
    orders: [],
    cdekPoint: null,
    reasons: null,
  },

  mutations: {
    SET_STATUSES(state, statuses) {
      state.deliveryStatuses = statuses.delivery_statuses;
      state.orderStatuses = statuses.order_statuses;
    },

    SET_ORDER(state, order) {
      state.order = order;
      if (order.type !== "cdek") {
        state.cdekPoint = null;
      }
    },

    SET_SINGLE_ORDER(state) {
      state.orders.data = [state.order];
      state.orders.meta.last_page = 1
    },

    SET_ORDERS(state, orders) {
      state.orders = orders;
      if (!state.orders.meta) {
        state.orders.meta = {
          current_page: orders.current_page,
          last_page: orders.last_page,
        }
      }
    },

    SET_ORDER_IN_XML_FORMAT(state, xml) {
      state.orderInXmlFormat = xml;
    },

    SET_POINT(state, payload) {
      state.cdekPoint = payload[0];
    },
    SET_REASONS(state, reasons) {
      state.reasons = reasons;
    },
  },

  actions: {
    async exportToXml({
      commit
    }, order_id) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.post(`/api/admin/orders/export-order-info/${order_id}`);
        commit('SET_ORDER_IN_XML_FORMAT', response.data);
        this.$notifySuccess("Заказ экспортирован в XML");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getOrder({
      commit
    }, order_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/orders/show/${order_id}`);
        commit("SET_ORDER", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getCdekPoint({
      commit
    }, pointId) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/sdekpoint-info/${pointId}`);
        commit("SET_POINT", response.data);
        return true;
      } catch (e) {
        console.error(e);
        this.$notifyError(e);
      }
    },

    async removeProductFromOrder(_, payload) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/api/admin/orders/delete-product-from-order', payload);
        this.$notifySuccess("Товар удален");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getUserOrdersById({
      commit
    }, user) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/api/admin/users/show/${user.id}?page=${user.page}`);
        commit("SET_ORDERS", response.data.orders);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getOrdersByDate({
      commit
    }, date) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/api/admin/orders/search-day/${date}`);
        commit("SET_ORDERS", response);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async rejectOrder(_, { id, reasonId}) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.post(`/api/admin/refunds/${id}/cancel`, {
          order_cancel_reason_id: reasonId,
        });

        if(!response.data.success) {
          this.$notifyError({response});
        }
        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getRejectReasons({ commit }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/api/admin/refunds/cancel-reasons`);
        commit("SET_REASONS", response.data.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },


    async searchOrders({
      commit
    }, payload) {
      try {
        let url = `/api/admin/orders/by-status/${payload.status}?`;
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.id || payload.id == 0 && payload.id !== "") {
          url += `&id=${payload.id}`;
        }

        if (payload.user_id || payload.user_id == 0 && payload.user_id !== "") {
          url += `&user_id=${payload.user_id}`;
        }

        if (payload.created_at) {
          url += `&created_at=${payload.created_at}`;
        }

        // if (payload.status || payload.status == 0) {
        //   url += `&status=${payload.status}`;
        // }

        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url);
        commit("SET_ORDERS", response.data);

      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getStatuses({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/api/admin/orders/get-statuses');
        commit('SET_STATUSES', response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateOrder(_, payload) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/api/admin/orders/update-status', payload);
        this.$notifySuccess("Заказ обновлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    }
  },

  getters: {},

  namespaced: true,
}
