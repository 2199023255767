export default {
  state: {
    regions: [{
        "id": 0,
        "name": "Республика Адыгея"
      },
      {
        "id": 1,
        "name": "Республика Алтай"
      },
      {
        "id": 2,
        "name": "Республика Башкортостан"
      },
      {
        "id": 3,
        "name": "Республика Бурятия"
      },
      {
        "id": 4,
        "name": "Республика Дагестан"
      },
      {
        "id": 5,
        "name": "Республика Ингушетия"
      },
      {
        "id": 6,
        "name": "Кабардино-Балкарская Республика"
      },
      {
        "id": 7,
        "name": "Республика Калмыкия"
      },
      {
        "id": 8,
        "name": "Карачаево-Черкесская Республика"
      },
      {
        "id": 9,
        "name": "Республика Карелия"
      },
      {
        "id": 10,
        "name": "Республика Коми"
      },
      {
        "id": 11,
        "name": "Республика Крым"
      },
      {
        "id": 12,
        "name": "Республика Марий Эл"
      },
      {
        "id": 13,
        "name": "Республика Мордовия"
      },
      {
        "id": 14,
        "name": "Республика Саха (Якутия)"
      },
      {
        "id": 15,
        "name": "Республика Северная Осетия – Алания"
      },
      {
        "id": 16,
        "name": "Республика Татарстан"
      },
      {
        "id": 17,
        "name": "Республика Тыва"
      },
      {
        "id": 18,
        "name": "Удмуртская Республика"
      },
      {
        "id": 19,
        "name": "Республика Хакасия"
      },
      {
        "id": 20,
        "name": "Чеченская Республика"
      },
      {
        "id": 21,
        "name": "Чувашская Республика – Чувашия"
      },
      {
        "id": 22,
        "name": "Алтайский край"
      },
      {
        "id": 23,
        "name": "Забайкальский край"
      },
      {
        "id": 24,
        "name": "Камчатский край"
      },
      {
        "id": 25,
        "name": "Краснодарский край"
      },
      {
        "id": 26,
        "name": "Красноярский край"
      },
      {
        "id": 27,
        "name": "Пермский край"
      },
      {
        "id": 28,
        "name": "Приморский край"
      },
      {
        "id": 29,
        "name": "Ставропольский край"
      },
      {
        "id": 30,
        "name": "Хабаровский край"
      },
      {
        "id": 31,
        "name": "Амурская область"
      },
      {
        "id": 32,
        "name": "Архангельская область"
      },
      {
        "id": 33,
        "name": "Астраханская область"
      },
      {
        "id": 34,
        "name": "Белгородская область"
      },
      {
        "id": 35,
        "name": "Брянская область"
      },
      {
        "id": 36,
        "name": "Владимирская область"
      },
      {
        "id": 37,
        "name": "Волгоградская область"
      },
      {
        "id": 38,
        "name": "Вологодская область"
      },
      {
        "id": 39,
        "name": "Воронежская область"
      },
      {
        "id": 40,
        "name": "Ивановская область"
      },
      {
        "id": 41,
        "name": "Иркутская область"
      },
      {
        "id": 42,
        "name": "Калининградская область"
      },
      {
        "id": 43,
        "name": "Калужская область"
      },
      {
        "id": 44,
        "name": "Кемеровская область"
      },
      {
        "id": 45,
        "name": "Кировская область"
      },
      {
        "id": 46,
        "name": "Костромская область"
      },
      {
        "id": 47,
        "name": "Курганская область"
      },
      {
        "id": 48,
        "name": "Курская область"
      },
      {
        "id": 49,
        "name": "Ленинградская область"
      },
      {
        "id": 50,
        "name": "Липецкая область"
      },
      {
        "id": 51,
        "name": "Магаданская область"
      },
      {
        "id": 52,
        "name": "Московская область"
      },
      {
        "id": 53,
        "name": "Мурманская область"
      },
      {
        "id": 54,
        "name": "Нижегородская область"
      },
      {
        "id": 55,
        "name": "Новгородская область"
      },
      {
        "id": 56,
        "name": "Новосибирская область"
      },
      {
        "id": 57,
        "name": "Омская область"
      },
      {
        "id": 58,
        "name": "Оренбургская область"
      },
      {
        "id": 59,
        "name": "Орловская область"
      },
      {
        "id": 60,
        "name": "Пензенская область"
      },
      {
        "id": 61,
        "name": "Псковская область"
      },
      {
        "id": 62,
        "name": "Ростовская область"
      },
      {
        "id": 63,
        "name": "Рязанская область"
      },
      {
        "id": 64,
        "name": "Самарская область"
      },
      {
        "id": 65,
        "name": "Саратовская область"
      },
      {
        "id": 66,
        "name": "Сахалинская область"
      },
      {
        "id": 67,
        "name": "Свердловская область"
      },
      {
        "id": 68,
        "name": "Смоленская область"
      },
      {
        "id": 69,
        "name": "Тамбовская область"
      },
      {
        "id": 70,
        "name": "Тверская область"
      },
      {
        "id": 71,
        "name": "Томская область"
      },
      {
        "id": 72,
        "name": "Тульская область"
      },
      {
        "id": 73,
        "name": "Тюменская область"
      },
      {
        "id": 74,
        "name": "Ульяновская область"
      },
      {
        "id": 75,
        "name": "Челябинская область"
      },
      {
        "id": 76,
        "name": "Ярославская область"
      },
      {
        "id": 77,
        "name": "Москва"
      },
      {
        "id": 78,
        "name": "Санкт-Петербург"
      },
      {
        "id": 79,
        "name": "Севастополь"
      },
      {
        "id": 80,
        "name": "Еврейская автономная область"
      },
      {
        "id": 81,
        "name": "Ненецкий автономный округ"
      },
      {
        "id": 82,
        "name": "Ханты-Мансийский автономный округ – Югра"
      },
      {
        "id": 83,
        "name": "Чукотский автономный округ"
      },
      {
        "id": 84,
        "name": "Ямало-Ненецкий автономный округ"
      }
    ]
  },

  mutations: {},

  actions: {},

  getters: {
    sortedByName: state => state.regions.sort((a, b) => {
      return a.name < b.name ? -1 : 1
    })
  },

  namespaced: true,
}
