"use strict";

import Vue from "vue";
import axios from "axios";

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
}

let config = {
  timeout: 60 * 1000,
};

const _axios = axios.create(config);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
