export default {
  state: {
    deliveryRegion: null,
    deliveryRegions: [],
  },

  mutations: {
    SET_DELIVERY_REGION(state, deliveryRegion) {
      state.deliveryRegion = deliveryRegion;
    },

    SET_DELIVERY_REGIONS(state, deliveryRegions) {
      state.deliveryRegions = deliveryRegions;
    },
  },

  actions: {
    async createDeliveryRegion(_, deliveryRegion) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post('/api/admin/delivery-cost-forecast/create', deliveryRegion);
        this.$notifySuccess("Стоимость доставки добавлена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getDeliveryRegions({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/delivery-cost-forecast/get-all`);
        commit("SET_DELIVERY_REGIONS", response.data);

      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getDeliveryRegion({
      commit
    }, deliveryRegion_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/delivery-cost-forecast/show/${deliveryRegion_id}`);
        commit("SET_DELIVERY_REGION", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async removeDeliveryRegion(_, deliveryRegion_id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.delete(`/api/admin/delivery-cost-forecast/delete/${deliveryRegion_id}`);
        this.$notifySuccess("Стоимость доставки удалена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateDeliveryRegion(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.put(`/api/admin/delivery-cost-forecast/update/${payload.id}`, payload.deliveryRegion);
        this.$notifySuccess("Стоимость доставки для региона обновлена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
