<template>
  <ValidationObserver ref="loginForm" :slim="true">
    <form class="login" @submit.prevent="login()">
      <ValidationProvider
        v-slot="{ errors, reset }"
        mode="passive"
        name="E-mail"
        :slim="true"
        rules="required|email"
      >
        <md-field :class="[{ error: errors.length }]">
          <ValidationError :errors="errors" />
          <label>Логин</label>
          <md-input
            v-model="email"
            placeholder="ivan@example.com"
            @focus="reset"
          />
        </md-field>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors, reset }"
        mode="passive"
        name="Пароль"
        :slim="true"
        rules="required|min:8|max:20"
      >
        <md-field
          :class="[{ error: errors.length }]"
          :md-toggle-password="true"
        >
          <ValidationError :errors="errors" />
          <label>Пароль</label>
          <md-input
            v-model="password"
            type="password"
            placeholder="********"
            @focus="reset"
          />
        </md-field>
      </ValidationProvider>

      <div class="btn-wrap">
        <md-button
          type="submit"
          class="md-primary md-raised login-btn"
          :disabled="showSpinner"
        >
          Войти
        </md-button>
        <div class="spin">
          <md-progress-spinner
            v-if="showSpinner"
            class="md-green"
            :md-diameter="15"
            :md-stroke="3"
            md-mode="indeterminate"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  data() {
    return {
      showSpinner: false,
      email: "",
      password: "",
    };
  },

  computed: {
    ...mapState({
      token: state => state.login.token,
      userId: state => state.login.userId,
    })
  },

  methods: {
    ...mapActions("login", ["signIn"]),
    ...mapMutations({
      addMessage: "chats/SEND_MESSAGE",
    }),

    async login() {
      this.showSpinner = true;
      const isValid = await this.$refs.loginForm.validate();
      if (!isValid) {
        this.showSpinner = false;
        return;
      }

      let success = await this.signIn({
        email: this.email,
        password: this.password,
      });
      if (success) {
        if (this.token) {
          this.$echo.connector.options.auth.headers.Authorization = "Bearer " + this.token;
          this.$echo.private(`App.Models.Admin.${this.userId}`).notification((notification) => {
            if (this.$route.path.includes("chats") && this.$route.params.id == notification.chat?.id) {
              this.addMessage(notification.message);
            }
          });
        }
        this.$router.push("/");
      }

      this.showSpinner = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 392px;
  width: 100%;

  &-btn {
    width: 100%;
    margin: 0;
  }

  .btn-wrap {
    position: relative;
    width: 100%;

    .spin {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
    }
  }
}
</style>
