<template>
  <div class="empty">
    <notifications />
    <router-view />
    <div class="empty-footer">
      <router-link to="#" class="empty-support"> поддержка </router-link>
      <div class="empty-copyrigth">
        {{ new Date().getFullYear() }} г, Appfox, все права защищены
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyLayout",
};
</script>

<style lang="scss">
.empty {
  background: white;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .empty-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 82px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e7e7e7;

    .empty-support {
      font-size: 12px;
      text-transform: uppercase;
      color: #495057;
    }

    .empty-copyrigth {
      font-size: 14px;
      color: #495057;
    }
  }

  &.my-empty {
    height: unset;
    min-height: 100px;
  }
}
</style>
