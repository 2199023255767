import router from "@/router";

export default {
    state: {
        orders: [],
        order: null,
        meta: null,
    },

    mutations: {
        SET_ORDERS(state, orders) {
            state.orders = orders;
        },
        SET_ORDER(state, order) {
            state.order = order;
        },
        SET_META(state, meta) {
            state.meta = meta;
        },
    },

    actions: {

        async getListOrders({
                              commit
                          }, id) {
            try {
                let url = `/api/admin/order-return?status=${id}`;

                /* eslint-disable-next-line no-undef */
                const response = await axios.get(url);

                commit("SET_ORDERS", response.data.data);
                commit("SET_META", response.data.meta);

            } catch (error) {
                console.error(error);
                this.$notifyError(error);
            }
        },
        async setStatus(_, { id, status, message}) {
            try {
                let url = `/api/admin/order-return/${id}/${status}-status`;

                const request = {
                        url: url,
                        method: status === 'rejected' ? 'post' : 'get',
                }
                if (message) {
                    request.data = {
                        message
                    };
                }

                /* eslint-disable-next-line no-undef */
                await axios(request);

                router.go();
                return true;

            } catch (error) {
                console.error(error);
                this.$notifyError(error);
            }
        },

        async getColor(_, color_id) {
            try {
                /* eslint-disable-next-line no-undef */
                const response = await axios.get(`/api/admin/colors/${color_id}`);

                return response.data.name;
            } catch (error) {
                console.error(error);
                this.$notifyError(error);
            }
        },

    },

    getters: {},

    namespaced: true,
}
