export default {
  state: {
    promotion: null,
    promotions: [],
  },

  mutations: {
    SET_PROMOTION(state, promotion) {
      state.promotion = promotion;
    },

    SET_PROMOTIONS(state, promotions) {
      state.promotions = promotions;
    },
  },

  actions: {
    async createPromotion(_, promotion) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post('/api/admin/promotions', promotion);
        this.$notifySuccess("Акция добавлена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getPromotion({
      commit
    }, promotion_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/promotions/${promotion_id}`);
        commit("SET_PROMOTION", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async removePromotion(_, promotion_id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.delete(`/api/admin/promotions/${promotion_id}`);
        this.$notifySuccess("Акция удалена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async searchPromotions({
      commit
    }, payload) {
      try {
        let url = "/api/admin/promotions/search?";
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.id || payload.id == 0) {
          url += `&id=${payload.id}`;
        }

        if (payload.title) {
          url += `&name=${payload.title}`;
        }

        if (payload.from_date) {
          url += `&from_date=${payload.from_date}`;
        }

        if (payload.to_date) {
          url += `&to_date=${payload.to_date}`;
        }

        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url);
        commit("SET_PROMOTIONS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updatePromotion(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post(`/api/admin/promotions/${payload.id}`, payload.promotion);
        this.$notifySuccess("Акция обновлена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
