export default {
  state: {
    brands: [],
    categories: [],
    colors: [],
    countries: [],
    currencies: [],
    seasons: [],
    storefronts: [],
    tags: [],
    vendors: [],
  },

  mutations: {
    SET_ADDITIONAL_DATA(state, payload) {
      state.brands = payload.brands;
      state.categories = payload.categories;
      state.colors = payload.colors;
      state.countries = payload.countries;
      state.seasons = payload.seasons;
      state.storefronts = payload.storefronts;
      state.tags = payload.tags;
      state.vendors = payload.vendors;
      state.currencies = payload.currencies.map(el => {
        return {
          id: el.id,
          value: el.location ? el.location.currency_code : null,
          exchangeRate: el.value,
        }
      });
      state.currencies.push({
        id: 0,
        value: "RUB",
      })
    },
  },

  actions: {
    async getAdditionalData({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get('/api/admin/products/data-for-product');
        commit("SET_ADDITIONAL_DATA", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    getVendorNameById: state => id => {
      let vendor = state.vendors.find((item) => item.id == id);
      return vendor ? vendor.name : "";
    },

    reworkedCategories: state => {
      let ids_arr = [];

      function dfsCategories(categories, childrenIncluded = false) {
        let arr = [];
        for (let i = 0; i < categories.length; i++) {
          let category = state.categories.find(category => category.id == categories[i].id)
          if (ids_arr.indexOf(category.id) == -1) {
            if (category.children?.length && !childrenIncluded) {
              category.children = dfsCategories(category.children, true);
            } else {
              category.children = undefined;
            }

            arr.push({
              id: category.id,
              label: category.name,
              children: category.children
            });
            ids_arr.push(category.id);
          }
        }
        return arr;
      }

      return dfsCategories(state.categories);
    }
  },

  namespaced: true,
}
