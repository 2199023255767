import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Plugins
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";

// A "safe" replacement for the v-html directive
import VueDOMPurifyHTML from "vue-dompurify-html";

import "@/plugins/axios";
import "@/plugins/vee-validate";
import "@/plugins/v-mask";
import "@/plugins/echo";

Vue.prototype.$Chartist = Chartist;

Vue.use(MaterialDashboard);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(VueDOMPurifyHTML);


Vue.material.locale.dateFormat = 'yyyy-MM-dd'

/* eslint-disable-next-line no-new */
const app = new Vue({
  data: {
    Chartist: Chartist
  },
  render: h => h(App),
  router,
  store
});

store.$notifySuccess = (message) => {
  app.$notify({
    message,
    icon: "add_alert",
    horizontalAlign: "right",
    verticalAlign: "top",
    type: "success",
  });
};

store.$notifyError = (error) => {
  if (error.response?.data?.errors) {
    for (let key in error.response.data.errors) {
      app.$notify({
        message: error.response.data.errors[key],
        icon: "highlight_off",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
    }
  } else {
    app.$notify({
      message: error?.response?.data?.message || "",
      icon: "highlight_off",
      horizontalAlign: "right",
      verticalAlign: "top",
      type: "danger",
    });
  }
}
app.$mount('#app');
