import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import middlewarePipeline from './middleware/middlewarePipeline'
import auth from './middleware/auth'
import superAdmin from './middleware/superAdmin'

import Login from "@/pages/Login";
import Settings from "@/pages/Settings";

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Settings',
  component: Settings,
  meta: {
    layout: "main",
    middleware: [
      auth,
    ]
  }
},

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: "empty",
    }
  },

  {
    path: '/admins',
    name: 'Admins',
    component: () => import('@/pages/Admins'),
    redirect: "/admins/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/admins/list",
      name: "Admins List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Admins/List`)
    },
      {
        path: "/admins/add",
        name: "Admin Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
            superAdmin
          ],
        },
        component: () => import(`@/pages/Admins/Add`)
      },
      {
        path: "/admins/:id",
        name: "Admin Info",
        meta: {
          layout: "main",
          middleware: [
            auth,
            superAdmin
          ],
        },
        component: () => import(`@/pages/Admins/Admin`)
      },
      {
        path: "/admins/edit/:id",
        name: "Admin Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
            superAdmin
          ],
        },
        component: () => import(`@/pages/Admins/Edit`)
      },
    ]
  },

  {
    path: '/users',
    name: 'Users',
    component: () => import('@/pages/Users'),
    redirect: "/users/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/users/list",
      name: "Users List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Users/List`)
    },
      {
        path: "/users/add",
        name: "User Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Users/Add`)
      },
      {
        path: "/users/:id",
        name: "User Info",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Users/User`)
      },
      {
        path: "/users/edit/:id",
        name: "User Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Users/Edit`)
      },
    ]
  },

  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/pages/Orders'),
    redirect: "/orders/new",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/orders/new",
      name: "Orders New",
      meta: {
        layout: "main",
        status: "new",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Orders/List`)
    },
      {
        path: "/orders/on-the-way",
        name: "Orders On The Way",
        meta: {
          layout: "main",
          status: "on-the-way",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Orders/List`)
      },
      {
        path: "/orders/completed",
        name: "Orders Completed",
        meta: {
          layout: "main",
          status: "completed",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Orders/List`)
      },
      {
        path: "/orders/:id",
        name: "Order Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Orders/Edit`)
      },
    ]
  },

  {
    path: '/return_page',
    name: 'Return',
    component: () => import('@/pages/ReturnOrder'),
    redirect: "/return_page",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/return_page/list",
      name: "return page",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/ReturnOrder/List`)
    },
      {
        path: "/return_page/:id",
        name: "return page",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/ReturnOrder/OrderInfo`)
      },
    ],
  },

  {
    path: '/products',
    name: 'Products',
    component: () => import('@/pages/Products'),
    redirect: "/products/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/products/list",
      name: "Products List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Products/List`)
    },
    {
      path: "/products/draft-list",
      name: "Products DraftList",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Products/DraftList`)
    },
      {
        path: "/products/add",
        name: "Product Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Products/Add`)
      },
      {
        path: "/products/:id",
        name: "Product Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Products/Edit`)
      },
    ]
  },

  {
    path: '/categories',
    name: 'Categories',
    component: () => import('@/pages/Categories'),
    redirect: "/categories/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/categories/list",
      name: "Categories List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Categories/List`)
    },
      {
        path: "/categories/add",
        name: "Category Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Categories/Add`)
      },
      {
        path: "/categories/:id",
        name: "Category Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Categories/Edit`)
      },
    ]
  },

  {
    path: '/vendors',
    name: 'Vendors',
    component: () => import('@/pages/Vendors'),
    redirect: "/vendors/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/vendors/list",
      name: "Vendors List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Vendors/List`)
    },
      {
        path: "/vendors/add",
        name: "Vendor Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Vendors/Add`)
      },
      {
        path: "/vendors/:id",
        name: "Vendor Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Vendors/Edit`)
      },
    ]
  },

  {
    path: '/delivery-cost',
    name: 'Delivery Cost',
    component: () => import('@/pages/DeliveryCost'),
    redirect: "/delivery-cost/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/delivery-cost/list",
      name: "Delivery Cost List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/DeliveryCost/List`)
    },
      {
        path: "/delivery-cost/add",
        name: "Delivery Cost Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/DeliveryCost/Add`)
      },
      {
        path: "/delivery-cost/:id",
        name: "Delivery Cost Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/DeliveryCost/Edit`)
      },
    ]
  },

  {
    path: '/colors',
    name: 'Colors',
    component: () => import('@/pages/Colors'),
    redirect: "/colors/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/colors/list",
      name: "Colors List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Colors/List`)
    },
      {
        path: "/colors/add",
        name: "Color Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Colors/Add`)
      },
      {
        path: "/colors/:id",
        name: "Color Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Colors/Edit`)
      },
    ]
  },

  {
    path: '/storefronts',
    name: 'Storefronts',
    component: () => import('@/pages/Storefronts'),
    redirect: "/storefronts/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/storefronts/list",
      name: "Storefronts List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Storefronts/List`)
    },
      {
        path: "/storefronts/add",
        name: "Storefront Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Storefronts/Add`)
      },
      {
        path: "/storefronts/:id",
        name: "Storefront Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Storefronts/Edit`)
      },
    ]
  },

  {
    path: '/brands',
    name: 'Brands',
    component: () => import('@/pages/Brands'),
    redirect: "/brands/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/brands/list",
      name: "Brands List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Brands/List`)
    },
      {
        path: "/brands/add",
        name: "Brand Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Brands/Add`)
      },
      {
        path: "/brands/:id",
        name: "Brand Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Brands/Edit`)
      },
    ]
  },

  {
    path: '/tags',
    name: 'Tags',
    component: () => import('@/pages/Tags'),
    redirect: "/tags/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/tags/list",
      name: "Tags List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Tags/List`)
    },
      {
        path: "/tags/add",
        name: "Tag Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Tags/Add`)
      },
      {
        path: "/tags/:id",
        name: "Tag Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Tags/Edit`)
      },
    ]
  },

  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/pages/Reports'),
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
  },

  {
    path: '/promotions',
    name: 'Promotions',
    component: () => import('@/pages/Promotions'),
    redirect: "/promotions/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/promotions/list",
      name: "Promotions List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Promotions/List`)
    },
      {
        path: "/promotions/add",
        name: "Promotion Add",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Promotions/Add`)
      },
      {
        path: "/promotions/:id",
        name: "Promotion Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Promotions/Edit`)
      },
    ]
  },

  {
    path: '/pushes',
    name: 'Pushes',
    component: () => import('@/pages/Pushes'),
    redirect: "/pushes/order-history",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/pushes/order-history",
      name: "Pushes Order History",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Pushes/OrderHistory`)
    },
      {
        path: "/pushes/cart",
        name: "Pushes Cart",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Pushes/Cart`)
      },
      {
        path: "/pushes/product-detail",
        name: "Pushes Product Detail",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Pushes/ProductDetail`)
      },
      {
        path: "/pushes/storefront-products",
        name: "Pushes Storefront Products",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Pushes/StorefrontProducts`)
      },
      {
        path: "/pushes/brand-products",
        name: "Pushes Brand Products",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Pushes/BrandProducts`)
      },
      {
        path: "/pushes/category-products",
        name: "Pushes Category Products",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Pushes/CategoryProducts`)
      },
    ]
  },

  {
    path: '/chats',
    name: 'Chats',
    component: () => import('@/pages/Chats'),
    redirect: "/chats/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [
      {
        path: "/chats/list",
        name: "Chats List",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Chats/List`)
      },
      {
        path: "/chats/:id",
        name: "Chat Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Chats/Edit`)
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: "nav-item active",
  linkActiveClass: "nav-item active"
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default router
