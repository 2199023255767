export default function superAdmin({
  next,
  store
}) {
  if (!store.getters['login/isSuperAdmin']) {
    return next({
      name: 'Settings'
    })
  }
  return next()
}
