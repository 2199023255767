export default {
  state: {
    color: null,
    colors: [],
  },

  mutations: {
    SET_COLOR(state, color) {
      state.color = color;
    },

    SET_COLORS(state, colors) {
      state.colors = colors;
    },
  },

  actions: {
    async createColor(_, color) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/api/admin/colors', color);
        this.$notifySuccess("Цвет добавлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getColor({
      commit
    }, color_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/colors/${color_id}`);
        commit("SET_COLOR", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async searchColors({
      commit
    }, payload) {
      try {
        let url = "/api/admin/colors/search?";
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.name) {
          url += `&name=${payload.name}`;
        }

        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url);
        commit("SET_COLORS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateColor(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.put(`/api/admin/colors/${payload.id}`, payload.color);
        this.$notifySuccess("Цвет обновлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
