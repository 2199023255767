export default {
  state: {
    tag: null,
    tags: [],
  },

  mutations: {
    SET_TAG(state, tag) {
      state.tag = tag;
    },

    SET_TAGS(state, tags) {
      state.tags = tags;
    },
  },

  actions: {
    async createTag(_, tag) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post('/api/admin/tags', tag);
        this.$notifySuccess("Тег добавлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getTag({
      commit
    }, tag_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/tags/${tag_id}`);
        commit("SET_TAG", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async searchTags({
      commit
    }, payload) {
      try {
        let url = "/api/admin/tags/search?";
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.id || payload.id == 0) {
          url += `&id=${payload.id}`;
        }

        if (payload.title) {
          url += `&title=${payload.title}`;
        }

        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url);
        commit("SET_TAGS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateTag(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.put(`/api/admin/tags/${payload.id}`, payload.tag);
        this.$notifySuccess("Тег обновлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async removeTag(_, tag_id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.delete(`/api/admin/tags/${tag_id}`);
        this.$notifySuccess("Тег удален");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
