export default {
  state: {
    brand: null,
    brands: [],
  },

  mutations: {
    SET_BRAND(state, brand) {
      state.brand = brand;
    },

    SET_BRANDS(state, brands) {
      state.brands = brands;
    },
  },

  actions: {
    async createBrand(_, brand) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post('/api/admin/brands', brand);
        this.$notifySuccess("Бренд добавлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getBrand({
      commit
    }, brand_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/brands/${brand_id}`);
        commit("SET_BRAND", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async searchBrands({
      commit
    }, payload) {
      try {
        let url = "/api/admin/brands/search?";
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.id || payload.id == 0) {
          url += `&id=${payload.id}`;
        }

        if (payload.name) {
          url += `&name=${payload.name}`;
        }

        if (payload.is_active || payload.is_active == 0) {
          url += `&is_active=${payload.is_active}`;
        }

        if (payload.is_main || payload.is_main == 0) {
          url += `&is_main=${payload.is_main}`;
        }

        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url);
        commit("SET_BRANDS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateBrand(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.put(`/api/admin/brands/${payload.id}`, payload.brand);
        this.$notifySuccess("Бренд обновлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
