export default {
  state: {
    admin: null,
    admins: [],
    admin_products: null,
    roles: [],
  },

  mutations: {
    SET_ADMIN(state, payload) {
      state.admin = payload ? payload.admin : null;
      state.admin_products = payload ? payload.admin_products : null;
    },

    SET_ADMINS(state, admins) {
      state.admins = admins;
    },

    SET_ROLES(state, roles) {
      state.roles = roles;
    },

    SET_SORT_KEY(state, key) {
      state.sortBy = key;
    },

    SET_SORT_ORDER(state, order) {
      state.sortOrder = order;
    }
  },

  actions: {
    async createAdmin(_, admin) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post("/api/super-admin/admin-account/create", admin);
        this.$notifySuccess("Администратор добавлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getAdmin({
      commit
    }, payload) {
      if (payload) {
        try {
          let url = `/api/super-admin/admin-account/show/${payload.admin_id}`;
          if (payload.page) {
            url += `?page=${payload.page}`;
          }
          /* eslint-disable-next-line no-undef */
          const response = await axios.get(url);
          commit("SET_ADMIN", response.data);

          return true;
        } catch (error) {
          console.error(error);
          this.$notifyError(error);
        }
      } else {
        commit("SET_ADMIN", null);
      }
    },

    async getRoles({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get("/api/super-admin/admin-account/get-admin-roles");
        commit("SET_ROLES", response.data);

      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async removeAdmin(_, id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.delete(`/api/super-admin/admin-account/delete/${id}`);
        this.$notifySuccess("Администратор удален");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async resetPassword(_, id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.get(`/api/super-admin/admin-account/regenerate-password/${id}`);
        this.$notifySuccess("Пароль сброшен");

      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async searchAdmins({
      commit
    }, payload) {
      try {
        let url = "/api/super-admin/admin-account/search-admins?";
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.id || payload.id == 0) {
          url += `&id=${payload.id}`;
        }

        if (payload.name) {
          url += `&name=${payload.name}`;
        }

        if (payload.phone) {
          url += `&phone=${payload.phone}`;
        }

        if (payload.email) {
          url += `&email=${payload.email}`;
        }
        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url);
        commit("SET_ADMINS", response.data);

      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateAdmin(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.put(`/api/super-admin/admin-account/update/${payload.id}`, payload.admin);
        this.$notifySuccess("Администратор изменен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
