<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Серверные настройки</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <div
            v-if="serverSettings && serverSettings.length"
            class="position-table"
          >
            <md-table v-model="serverSettings">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID" class="custom-field">
                  <span>{{ item.id }}</span>
                </md-table-cell>

                <md-table-cell md-label="Название" class="custom-field">
                  <span :title="item.name">{{ item.name }}</span>
                </md-table-cell>

                <md-table-cell
                  v-if="item.key !== 'currency_conversion_commission'"
                  md-label="Значение"
                  class="custom-field"
                >
                  <span>{{ item.value }}</span>
                </md-table-cell>

                <md-table-cell v-else md-label="Значение" class="custom-field">
                  <span v-show="!item.isEdit">{{ item.value }}</span>
                  <ValidationProvider
                    ref="commissionValidator"
                    v-slot="{ errors, reset }"
                    mode="passive"
                    :slim="true"
                    rules="required"
                  >
                    <md-field
                      v-show="item.isEdit"
                      :class="{ error: errors.length }"
                    >
                      <ValidationError :errors="errors" />
                      <label>Значение</label>
                      <md-input
                        v-model="item.value"
                        type="number"
                        min="0"
                        step="0.01"
                        @focus="reset"
                        @change="
                          onValueChange('value', item, $event.target.value)
                        "
                      ></md-input>
                    </md-field>
                  </ValidationProvider>
                </md-table-cell>

                <md-table-cell>
                  <template v-if="item.key == 'currency_conversion_commission'">
                    <div v-if="!item.isEdit" class="edit-and-delete-block">
                      <md-button
                        class="md-just-icon md-simple md-primary"
                        @click="item.isEdit = true"
                      >
                        <md-icon>edit</md-icon>
                        <md-tooltip md-direction="top"
                          >Редактировать</md-tooltip
                        >
                      </md-button>
                    </div>
                    <div v-else class="save-block">
                      <md-button
                        class="md-just-icon md-simple md-success"
                        @click="saveNewCommission(item)"
                      >
                        <md-icon>save</md-icon>
                        <md-tooltip md-direction="top">Сохранить</md-tooltip>
                      </md-button>
                      <md-button
                        class="md-just-icon md-simple md-danger"
                        @click="cancelEditCommission(item)"
                      >
                        <md-icon>cancel</md-icon>
                        <md-tooltip md-direction="top">Отмена</md-tooltip>
                      </md-button>
                    </div>
                  </template>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          <div v-else class="my-empty empty">Пусто</div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Настройки регионов</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <div
            v-if="locationSettings && locationSettings.length"
            class="position-table"
          >
            <ValidationObserver ref="locationSettingsForm" :slim="true">
              <md-table v-model="locationSettings">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="ID" class="custom-field">
                    <span>{{ item.id }}</span>
                  </md-table-cell>

                  <md-table-cell md-label="Регион" class="custom-field">
                    <span>{{ item.location.name }}</span>
                  </md-table-cell>

                  <md-table-cell
                    md-label="Цена за килограмм"
                    class="custom-field"
                  >
                    <span v-show="!item.isEdit">{{ item.kilogram_price }}</span>
                    <ValidationProvider
                      v-slot="{ errors, reset }"
                      mode="passive"
                      :slim="true"
                      rules="required"
                    >
                      <md-field
                        v-show="item.isEdit"
                        :class="{ error: errors.length }"
                      >
                        <ValidationError :errors="errors" />
                        <label>Цена за килограмм</label>
                        <md-input
                          v-model="item.kilogram_price"
                          type="number"
                          min="0"
                          @focus="reset"
                          @change="
                            onValueChange(
                              'kilogram_price',
                              item,
                              $event.target.value
                            )
                          "
                        ></md-input>
                      </md-field>
                    </ValidationProvider>
                  </md-table-cell>

                  <md-table-cell
                    md-label="Региональная надбавка"
                    class="custom-field"
                  >
                    <span v-show="!item.isEdit">{{ item.allowance }}</span>
                    <ValidationProvider
                      v-slot="{ errors, reset }"
                      mode="passive"
                      :slim="true"
                      rules="required"
                    >
                      <md-field
                        v-show="item.isEdit"
                        :class="{ error: errors.length }"
                      >
                        <ValidationError :errors="errors" />
                        <label>Региональная надбавка</label>
                        <md-input
                          v-model="item.allowance"
                          type="number"
                          min="0"
                          @focus="reset"
                          @change="
                            onValueChange(
                              'allowance',
                              item,
                              $event.target.value
                            )
                          "
                        ></md-input>
                      </md-field>
                    </ValidationProvider>
                  </md-table-cell>

                  <md-table-cell>
                    <div v-if="!item.isEdit" class="edit-and-delete-block">
                      <md-button
                        class="md-just-icon md-simple md-primary"
                        @click="item.isEdit = true"
                      >
                        <md-icon>edit</md-icon>
                        <md-tooltip md-direction="top"
                          >Редактировать</md-tooltip
                        >
                      </md-button>
                    </div>
                    <div v-else class="save-block">
                      <md-button
                        class="md-just-icon md-simple md-success"
                        @click="saveNewLocationSetting(item)"
                      >
                        <md-icon>save</md-icon>
                        <md-tooltip md-direction="top">Сохранить</md-tooltip>
                      </md-button>
                      <md-button
                        class="md-just-icon md-simple md-danger"
                        @click="cancelEditLocationSetting(item)"
                      >
                        <md-icon>cancel</md-icon>
                        <md-tooltip md-direction="top">Отмена</md-tooltip>
                      </md-button>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </ValidationObserver>
          </div>
          <div v-else class="my-empty empty">Пусто</div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      locationSettings: (state) => state.settings.locationSettings,
      serverSettings: (state) => state.settings.serverSettings,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getLocationSettings();
    await this.getServerSettings();
    await this.getCounters();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions(["getCounters"]),
    ...mapActions("settings", [
      "getLocationSettings",
      "getServerSettings",
      "updateConversionCommission",
      "updateLocationSetting",
    ]),

    async cancelEditCommission(item) {
      item.isEdit = false;
      this.$store.commit("SET_SHOW_LOADER", true);
      await this.getServerSettings();
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    async cancelEditLocationSetting(item) {
      item.isEdit = false;
      this.$store.commit("SET_SHOW_LOADER", true);
      await this.getLocationSettings();
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    onValueChange(field, item, value) {
      if (value < 0) {
        switch (field) {
          case "value":
            item.value = 0;
            break;
          case "kilogram_price":
            item.kilogram_price = 0;
            break;
          case "allowance":
            item.allowance = 0;
            break;
          default:
            break;
        }
      }
    },

    async saveNewCommission(item) {
      const isValid = await this.$refs.commissionValidator.validate();
      if (!isValid.valid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateConversionCommission({
        commission: item.value,
      });
      if (success) {
        await this.getServerSettings();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    async saveNewLocationSetting(item) {
      const isValid = await this.$refs.locationSettingsForm.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateLocationSetting({
        location_id: item.location_id,
        kilogram_price: item.kilogram_price,
        allowance: item.allowance,
      });

      if (success) {
        await this.getLocationSettings();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-layout {
  max-width: 100%;
}
</style>