<template>
  <div v-if="errors.length" class="validation-error">
    <p class="validation-error__text">
      {{ errors[0] }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.validation-error {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(90%);
  z-index: 2;

  &__text {
    white-space: nowrap;
    font-size: 14px;
    color: red;
    margin: 0;
  }
}
</style>
