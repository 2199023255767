import Vue from 'vue'
import Vuex from 'vuex'

import additionalData from './modules/additionalData';
import admins from "./modules/admins";
import brands from './modules/brands';
import categories from './modules/categories';
import chats from './modules/chats';
import colors from './modules/colors';
import deliveryCost from './modules/deliveryCost';
import login from "./modules/login";
import orders from "./modules/orders";
import products from "./modules/products";
import promotions from './modules/promotions';
import pushes from './modules/pushes';
import regions from "./modules/regions";
import reports from './modules/reports';
import settings from './modules/settings';
import storefronts from './modules/storefronts';
import tags from './modules/tags';
import users from "./modules/users";
import vendors from "./modules/vendors";
import returnPage from "./modules/returnPage"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    counters: null,
    showLoader: false,
  },

  mutations: {
    SET_COUNTERS(state, counters) {
      state.counters = counters;
    },

    SET_SHOW_LOADER(state, flag) {
      state.showLoader = flag;
    }
  },

  actions: {
    async getCounters({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get('/api/admin/server-settings/informer-counter');
        commit("SET_COUNTERS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  modules: {
    additionalData,
    admins,
    brands,
    categories,
    chats,
    colors,
    deliveryCost,
    login,
    orders,
    products,
    promotions,
    pushes,
    regions,
    reports,
    settings,
    storefronts,
    tags,
    users,
    vendors,
    returnPage,
  },
})
