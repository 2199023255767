export default {
  state: {
    types: [],
  },

  mutations: {
    SET_TYPES(state, types) {
      state.types = types;
    },
  },

  actions: {
    async getTypes({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/push-message/get-types`);
        commit("SET_TYPES", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async sendPush(_, push) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post('/api/admin/push-message/send-push', push);
        this.$notifySuccess("Пуш отправлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
