export default {
  state: {
    categories: [],
    category: null,
  },

  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },

    SET_CATEGORY(state, category) {
      state.category = category;
    },
  },

  actions: {
    async createCategory(_, category) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post('/api/admin/category/create', category);
        this.$notifySuccess("Категория добавлена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getCategories({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/category/get-all`);
        commit("SET_CATEGORIES", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getCategory({
      commit
    }, category_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/category/show/${category_id}`);
        commit("SET_CATEGORY", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async removeCategory(_, category_id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.delete(`/api/admin/category/destroy/${category_id}`);
        this.$notifySuccess("Категория удалена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateCategory(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post(`/api/admin/category/update/${payload.id}`, payload.category);
        this.$notifySuccess("Категория обновлена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    sortedCategories: state => {
      function dfsCategories(categories, level) {
        let arr = [];
        for (let i = 0; i < categories.length; i++) {
          let category = JSON.parse(JSON.stringify(state.categories.find(category => category.id == categories[i].id)));
          category.level = level;
          arr.push(category);
          if (category.children?.length && level === 0) {
            arr = arr.concat(dfsCategories(category.children, level + 1));
          }
        }
        return arr;
      }

      let sortedCategories = dfsCategories(state.categories, 0);

      return sortedCategories.filter((elem, index, self) => self.findIndex(item => item.id == elem.id) == index);
    }
  },

  namespaced: true,
}
