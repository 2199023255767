export default {
  state: {
    report: null,
    reports: [],
    statuses: [],
  },

  mutations: {
    SET_REPORT(state, report) {
      state.report = report;
    },

    SET_REPORTS(state, reports) {
      state.reports = reports;
    },

    SET_STATUSES(state, statuses) {
      state.statuses = statuses;
    }
  },

  actions: {
    async changeStatus(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.put(`/api/admin/order-reports/change-status/${payload.id}`, payload.answer);
        this.$notifySuccess("Статус репорта обновлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getReport({
      commit
    }, report_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/order-reports/show/${report_id}`);
        commit("SET_REPORT", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getStatuses({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/order-reports/get-statuses`);
        commit("SET_STATUSES", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async searchReports({
      commit
    }, payload) {
      try {
        let url = "/api/admin/order-reports/search?";
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.id || payload.id == 0) {
          url += `&id=${payload.id}`;
        }

        if (payload.order_id || payload.order_id == 0) {
          url += `&id=${payload.id}`;
        }

        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url);
        commit("SET_REPORTS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
