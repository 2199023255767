export default {
  state: {
    locationSettings: [],
    serverSettings: [],
  },

  mutations: {
    SET_LOCATION_SETTINGS(state, settings) {
      settings.forEach((setting) => {
        setting.isEdit = false
      });

      state.locationSettings = settings;
    },

    SET_SERVER_SETTINGS(state, settings) {
      settings.forEach((setting) => {
        setting.isEdit = false
      });

      state.serverSettings = settings;
    },
  },

  actions: {
    async getLocationSettings({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get('/api/admin/location-settings');
        commit("SET_LOCATION_SETTINGS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getServerSettings({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get('/api/admin/server-settings/get-all');
        commit("SET_SERVER_SETTINGS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateConversionCommission(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post(`/api/admin/server-settings/update/conversion-commission`, payload);
        this.$notifySuccess("Настройки обновлены");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateLocationSetting(_, setting) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post(`/api/admin/location-settings/update`, setting);
        this.$notifySuccess("Настройки обновлены");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
