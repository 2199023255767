<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications />

    <SideBar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <SidebarLink
        v-for="(link, index) in links"
        :key="`link_${index}`"
        :to="link.path"
        :exact="link.path == '/'"
      >
        <p>{{ link.title }}</p>
        <div v-if="link.withCounter && counters" class="counter">
          {{ counters[link.withCounter] }}
        </div>
      </SidebarLink>
    </SideBar>

    <div class="main-panel">
      <TopNavbar />

      <DashboardContent />
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import DashboardContent from "./Content.vue";

import { mapState } from "vuex";

export default {
  components: {
    TopNavbar,
    DashboardContent,
  },

  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      links: [
        {
          path: "/",
          title: "Настройки",
        },
        {
          path: "/admins",
          title: "Администраторы",
        },
        {
          path: "/users",
          title: "Пользователи",
        },
        {
          path: "/products",
          title: "Товары",
        },
        {
          path: "/orders",
          title: "Заказы",
          withCounter: "orders",
        },
        {
          path: "/categories",
          title: "Категории",
        },
        {
          path: "/vendors",
          title: "Поставщики",
        },
        {
          path: "/colors",
          title: "Цвета",
        },
        {
          path: "/storefronts",
          title: "Витрины",
        },
        {
          path: "/brands",
          title: "Бренды",
        },
        {
          path: "/tags",
          title: "Теги",
        },
        {
          path: "/reports",
          title: "Обратная связь",
          withCounter: "reports",
        },
        {
          path: "/delivery-cost",
          title: "Стоимость доставки",
        },
        {
          path: "/promotions",
          title: "Акции",
        },
        {
          path: "/pushes",
          title: "Отправка пушей",
        },
        {
          path: "/chats",
          title: "Чаты",
          withCounter: "chats",
        },
        {
          path: "/return_page/list",
          title: "Возврат товара",
        },
      ],
    };
  },

  computed: {
    ...mapState(["counters"]),
  },
};
</script>

<style lang="scss" scoped>
.counter {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}
</style>
