export default {
  state: {
    chats: {},
    messages: [],
  },

  mutations: {
    SET_CHATS(state, payload) {
      state.chats = payload;
    },

    SET_MESSAGES(state, payload) {
      state.messages = payload.reverse();
    },

    SEND_MESSAGE(state, payload) {
      state.messages.push(payload);
    }
  },

  actions: {
    async getChats({
      commit
    }) {
      /* eslint-disable-next-line no-undef */
      await axios.get("/api/admin/refund-chat")
        .then((res) => commit("SET_CHATS", res.data))
    },

    async getChatMessages({
      commit
    }, id) {
      /* eslint-disable-next-line no-undef */
      await axios.get(`/api/admin/refund-chat/${id}/message?limit=10000`)
        .then((res) => commit("SET_MESSAGES", res.data))
    },

    async sendMessage({
      commit
    }, payload) {
      const fd = new FormData;
      if (payload.files) {
        for (let i = 0; i < payload.files.length; i++) {
          if (payload.files[i].type.includes("image")) {
            fd.append("images[][file]", payload.files[i]);
            fd.append("images[][name]", payload.files[i].name);
          }
          if (payload.files[i].type.includes("application") || payload.files[i].type.includes("text") ) {
            fd.append("docs[][file]", payload.files[i]);
            fd.append("docs[][name]", payload.files[i].name);
          }
          if (payload.files[i].type.includes("video")) {
            fd.append("video[][file]", payload.files[i]);
            fd.append("video[][name]", payload.files[i].name);
          }
        }
      }
      if(!payload.product_ids) {
        payload.product_ids = [];
      }
      payload.product_ids.map(value => {
        fd.append("product_ids[]",  value);
      })

      fd.append("id", payload.id);
      fd.append("message", payload.message || "");
      /* eslint-disable-next-line no-undef */
      console.log(1);
      await axios.post(`/api/admin/refund-chat/${payload.id}/message`, fd)
        .then((res) => commit("SEND_MESSAGE", res.data))
          .catch((error) => {
            this.$notifyError(error);
          });
    }
  },

  namespaced: true,
}
