<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>

    <Loader v-if="showLoader" />
  </div>
</template>

<script>
import EmptyLayout from "@/pages/Layout/Empty.vue";
import Loader from "@/components/ui/Loader";
import MainLayout from "@/pages/Layout/Main.vue";

import { mapState, mapMutations } from "vuex";

export default {
  components: {
    EmptyLayout,
    Loader,
    MainLayout,
  },

  computed: {
    ...mapState(["showLoader"]),
    ...mapState("login", ["userId"]),

    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },

  created() {
    this.axios.interceptors.response.use(
      undefined,
      (error) =>
        new Promise(() => {
          const { status } = error.response;
          if (status == 401) {
            this.$store.dispatch("login/logout");
            this.$router.push("/login");
            this.$store.commit("SET_SHOW_LOADER", false);
          }
          throw error;
        })
    );
  },

  mounted() {
    if (localStorage.getItem("myItalyToken")) {
      let token = localStorage.getItem("myItalyToken"),
        role = localStorage.getItem("myItalyRole"),
        userId = localStorage.getItem("myItalyId");
      this.$store.commit("login/LOGGED_IN", { token, role, userId });
      this.$router.push("/");
      this.$echo.options.bearerToken = localStorage.getItem("myItalyToken");
      this.$echo.private(`App.Models.Admin.${this.userId}`).notification((notification) => {
        if (this.$route.path.includes("chats") && this.$route.params.id == notification.chat?.id) {
          this.addMessage(notification.message);
        }
      });
    }
  },

  methods: {
    ...mapMutations({
      addMessage: "chats/SEND_MESSAGE",
    })
  }
};
</script>
