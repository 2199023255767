export default {
  state: {
    token: null,
    role: null,
    userId: null,
  },

  mutations: {
    LOGGED_IN(state, payload) {
      state.token = payload.token;
      state.role = payload.role;
      state.userId = payload.userId;

      if (!localStorage.getItem('myItalyToken') && payload.token) {
        localStorage.setItem('myItalyToken', payload.token);
        localStorage.setItem('myItalyRole', payload.role);
        localStorage.setItem('myItalyId', payload.userId);
      }

      /* eslint-disable-next-line no-undef */
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + payload.token;
    },
  },

  actions: {
    async signIn({
      commit
    }, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.post("/api/admin-login/login", {
          email: payload.email,
          password: payload.password
        });

        commit("LOGGED_IN", {
          token: response.data.token,
          role: response.data.data.role,
          userId: response.data.data.id
        });
        this.$notifySuccess("Вход выполнен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    logout({
      commit
    }) {
      localStorage.removeItem("myItalyToken");
      localStorage.removeItem("myItalyRole");
      localStorage.removeItem("myItalyId");
      commit("LOGGED_IN", {
        token: null,
        role: null,
        userId: null
      });
    }
  },

  getters: {
    isSuperAdmin: state => state.role == 'super-admin'
  },

  namespaced: true
}
