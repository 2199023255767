export default {
  state: {
    orders: null,
    user: null,
    users: [],
  },

  mutations: {
    SET_USER(state, payload) {
      state.orders = payload ? payload.orders : null;
      state.user = payload ? payload.user : null;
    },

    SET_USERS(state, users) {
      state.users = users;
    },
  },

  actions: {
    async createUser(_, user) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post("/api/admin/users/create", user);
        this.$notifySuccess("Пользователь добавлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getUser({
      commit
    }, payload) {
      if (payload) {
        try {
          let url = `/api/admin/users/show/${payload.user_id}`;
          if (payload.page) {
            url += `?page=${payload.page}`;
          }
          /* eslint-disable-next-line no-undef */
          const response = await axios.get(url);
          commit("SET_USER", response.data);

          return true;
        } catch (error) {
          console.error(error);
          this.$notifyError(error);
        }
      } else {
        commit("SET_USER", null);
      }

    },

    async removeUser(_, id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.delete(`/api/admin/users/destroy/${id}`);
        this.$notifySuccess("Пользователь удален");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async resetPassword(_, id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.get(`/api/admin/users/regenerate-password/${id}`);
        this.$notifySuccess("Пароль сброшен");
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async searchUsers({
      commit
    }, payload) {
      try {
        let url = "/api/admin/users/search-users?";
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.id || payload.id == 0) {
          url += `&id=${payload.id}`;
        }

        if (payload.name) {
          url += `&name=${payload.name}`;
        }

        if (payload.phone) {
          url += `&phone=${payload.phone}`;
        }

        if (payload.email) {
          url += `&email=${payload.email}`;
        }
        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url);
        commit("SET_USERS", response.data);

      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateUser(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post(`/api/admin/users/update/${payload.id}`, payload.user);
        this.$notifySuccess("Пользователь изменен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
