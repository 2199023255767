export default {
  state: {
    storefront: null,
    storefronts: [],
  },

  mutations: {
    SET_STOREFRONT(state, storefront) {
      state.storefront = storefront;
    },

    SET_STOREFRONTS(state, storefronts) {
      state.storefronts = storefronts;
    },
  },

  actions: {
    async createStorefront(_, storefront) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/api/admin/storefronts/create', storefront);
        this.$notifySuccess("Витрина добавлена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getStorefront({
      commit
    }, storefront_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/storefronts/show/${storefront_id}`);
        commit("SET_STOREFRONT", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getStorefronts({
      commit
    }) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/storefronts/get-all`);
        commit("SET_STOREFRONTS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async removeStorefront(_, storefront_id) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.delete(`/api/admin/storefronts/destroy/${storefront_id}`);
        this.$notifySuccess("Витрина удалена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateStorefront(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post(`/api/admin/storefronts/update/${payload.id}`, payload.storefront);
        this.$notifySuccess("Витрина обновлена");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
