export default {
  state: {
    vendors: [],
    vendor: null,
  },

  mutations: {
    SET_VENDOR(state, vendor) {
      state.vendor = vendor;
    },

    SET_VENDORS(state, vendors) {
      state.vendors = vendors;
    }
  },

  actions: {
    async createVendor(_, vendor) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.post('/api/admin/vendors', vendor);
        this.$notifySuccess("Поставщик добавлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getVendor({
      commit
    }, vendor_id) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.get(`/api/admin/vendors/${vendor_id}`);
        commit("SET_VENDOR", response.data);

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async searchVendors({
      commit
    }, payload) {
      try {
        let url = "/api/admin/vendors/search?";
        url += `sort_key=${payload.sortBy}`;
        url += `&sort_method=${payload.sortOrder}`;
        url += `&page=${payload.page}`;

        if (payload.id || payload.id == 0) {
          url += `&id=${payload.id}`;
        }

        if (payload.name) {
          url += `&name=${payload.name}`;
        }

        if (payload.is_active || payload.is_active == 0) {
          url += `&is_active=${payload.is_active}`;
        }

        /* eslint-disable-next-line no-undef */
        const response = await axios.post(url);
        commit("SET_VENDORS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async updateVendor(_, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        await axios.put(`/api/admin/vendors/${payload.id}`, payload.vendor);
        this.$notifySuccess("Поставщик обновлен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true,
}
