import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import Vue from 'vue';

window.Pusher = Pusher;

export var echo_instance = new Echo({
  broadcaster: 'pusher',
  key: 'laravel',
  cluster: '',
  wsHost: "45.67.59.194",
  wsPort: 6001,
  forceTLS: false,
  authEndpoint: 'http://my-italy.online/api/broadcasting/auth',
  disableStats: true,
  bearerToken: localStorage.getItem("myItalyToken"),
  enabledTransports: ['ws', 'wss'],
});

Vue.prototype.$echo = echo_instance;

export default Vue;
